<template>
<div>
  <h3 class="w-full m-5">Dealer Details</h3>
  <vs-row>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="name">Dealer Name</label>
        <p id="name">{{data.name?data.name:"N/A"}}</p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="displayAddress">Display Address</label>
        <p id="displayAddress">{{data.displayAddress?data.displayAddress:"N/A"}}</p>
      </div>
    </vs-col>

    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="licenseNumber">License Number</label>
        <p id="licenseNumber">{{data.licenseNumber?data.licenseNumber:"N/A"}}</p>
      </div>
    </vs-col>

    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="licenseNumber">Dealer ID</label>
        <p id="licenseNumber">{{data.dealerID?data.dealerID:"N/A"}}</p>
      </div>
    </vs-col>
  </vs-row>

  <h3 class="w-full m-5">Dealer Contact Information</h3>
  <vs-row>
    
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="contactName">Contact Name</label>
        <p id="contactName">{{data.contactInformation?data.contactInformation.contactName:"N/A"}}</p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="email">Contact Email</label>
        <p id="email">{{data.contactInformation?data.contactInformation.email:"N/A"}}</p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="contactPhone">Contact Mobile</label>
        <p id="contactPhone">{{data.contactInformation?data.contactInformation.contactPhone:"N/A"}}</p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="contactTele">Contact Telephone</label>
        <p id="contactTelePhone">{{data.contactInformation?data.contactInformation.contactTelePhone:"N/A"}}</p>
      </div>
    </vs-col>
    
  </vs-row>
</div>
</template>


<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>