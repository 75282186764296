<template>
  <vx-card style="padding-left: 10px">
    <!-- <vs-label style="font-weight: bold"> -->

    <!-- </vs-label> -->
    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="cars"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
      >
        <div
          slot="header"
          class="flex flex-wrap-reverse items-center flex-grow justify-between"
        >
          <vx-tooltip text="Add New Car" position="top" delay=".3s">
            <vs-button
              type="gradient"
              size="small"
              icon-pack="feather"
              icon="icon-plus"
              color="success"
              @click="addCarHandler"
            ></vs-button>
          </vx-tooltip>
          <div class="flex">
            <v-select
              v-model="dataTableParams.limit"
              class="mr-4"
              :options="limitOptions"
              :clearable="false"
            />
            <div class="flex  flex-end">
              <vs-input icon-pack="feather" icon="icon-search" placeholder="Search" v-model="dataTableParams.search" class="w-full"/>
            </div>
          </div>
        </div>

        <template slot="thead">
          <vs-th sort-key="make">Owner</vs-th>
          <vs-th >Image</vs-th>
          <vs-th sort-key="make">Make</vs-th>
          <vs-th sort-key="model">Model</vs-th>
          <vs-th sort-key="year">Year</vs-th>
          <vs-th sort-key="sellerType">Listing Type</vs-th>
          <vs-th sort-key="status">Status</vs-th>
          <vs-th sort-key="createdAt">Created At</vs-th>
          <vs-th sort-key="action" size="25%">Actions</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].dealerId.name">{{ data[indextr].dealerId.name }}</vs-td>
            <vs-td class="td vs-table--td" :data="data[indextr].mainImage"> 
              <div class="img-container">
                <div class="image">
                  <img :src="data[indextr].mainImage" @click="viewDetailHandler(data[indextr]._id)"/>
                </div> 
              </div>
            </vs-td>
            <vs-td :data="data[indextr].make">{{ data[indextr].make }}</vs-td>
            <vs-td :data="data[indextr].model">{{ data[indextr].model }}</vs-td>
            <vs-td :data="data[indextr].year">{{ data[indextr].year }}</vs-td>
            <vs-td :data="data[indextr].sellerType">{{ "Dealer" }}</vs-td>
            <vs-td :data="data[indextr].isDrafted">{{
              data[indextr].isDrafted ? "In Draft" : "Listed"
            }}</vs-td>
            <vs-td :data="data[indextr].createdAt">{{
              moment(data[indextr].createdAt).format("DD/MM/YYYY")
            }}</vs-td>
           
            <vs-td :data="data[indextr]._id">
              <vs-row vs-type="flex" vs-justify="space-between">
                <vx-tooltip text="View" position="top" delay=".3s">
                  <vs-button
                    type="gradient"
                    size="small"
                    icon-pack="feather"
                    @click="viewDetailHandler(data[indextr]._id)"
                    icon="icon-info"
                    color="success"
                  ></vs-button>
                </vx-tooltip>
                <vx-tooltip text="Edit" position="top" delay=".3s">
                  <vs-button
                    type="gradient"
                    size="small"
                    icon-pack="feather"
                    @click="editDetailHandler(data[indextr]._id)"
                    icon="icon-edit"
                    color="warning"
                  ></vs-button>
                </vx-tooltip>
                <vx-tooltip text="Delete" position="top" delay=".3s">
                  <vs-button
                    type="gradient"
                    size="small"
                    icon-pack="feather"
                    @click="confirmDeleteRecord(data[indextr], indextr)"
                    icon="icon-trash"
                    color="danger"
                  ></vs-button>
                </vx-tooltip>
              </vs-row>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <span class="mr-2"
        >{{ currentPage * dataTableParams.limit - (dataTableParams.limit - 1) }}
        -
        {{
          totalDocs - currentPage * dataTableParams.limit > 0
            ? currentPage * dataTableParams.limit
            : totalDocs
        }}
        of {{ totalDocs }}</span
      >
      <vs-pagination
        :total="totalPage"
        v-model="dataTableParams.page"
      ></vs-pagination>
    </div>
  </vx-card>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  props: ["id"],
  components: {
    "v-select": vSelect
  },
  data() {
    return {
      isMounted: false,
      totalDocs: 0,
      currentPage: 1,
      dataTableParams: {
        userId: "",
        search: this.$store.getters.dealerVehiclePageSearch,
        sort: "createdAt",
        dir: "desc",
        page: this.$store.getters.dealerVehiclePage,
        limit: this.$store.getters.dealerVehiclePageLimit,
      },
      cars: [],
      limitOptions: [5, 10, 25, 50, 100],
      selectedCar: {},
    };
  },
  methods: {
    ...mapActions("carDealer", ["fetchDealerCarList", "deleteDealerCar"]),
    ...mapActions(["updateDealerVehiclePage", "initToFirstPage", "updateDealerVehiclePageLimit", "updateDealerVehiclePageSearch"]),
    moment(date) {
      return moment(date);
    },
    checkStatus(dateTime) {
      let now = Date.now();
      var date = new Date(dateTime);
      var milliseconds = date.getTime();
      if (now < milliseconds) {
        return true;
      } else {
        return false;
      }
    },
    viewDetailHandler(carId) {
      this.$router.push({ name: "VehicleDetails", params: { id: carId } });
    },
    editDetailHandler(carId) {
      this.$router.push({ name: "EditCar", params: { id: this.id, carId: carId } });
    },
    getDealerCarList() {
      let self = this;
      self.dataTableParams.id = this.id;
      this.fetchDealerCarList(self.dataTableParams).then(res => {
        self.cars = res.data.data.docs;
        self.totalDocs = res.data.data.pagination.total;
        self.page = res.data.data.pagination.page;
        self.currentPage = self.page;
      });
    },

    addCarHandler(){
      this.$router.push({ name: "AddNewCar", params: { id: this.id } });
    },
    handleSearch(searching) {
      this.dataTableParams.search = searching;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getDealerCarList();
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getDealerCarList();
    },
    handleSort(key, active) {
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getDealerCarList();
    },

    confirmDeleteRecord(dealer, i) {
      this.selectedCar = dealer;
      this.selectedIndex = i;

      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm Delete`,
        text: `You are about to delete "${dealer.make}"`,
        accept: this.confirmDelete,
        acceptText: "Delete",
      });
    },
    confirmDelete() {
      this.deleteDealerCar(this.selectedCar._id).then((res) => {
        this.$vs.notify({
          title: "Car Deleted",
          text: res.data.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "success",
        });
        this.cars.splice(this.selectedIndex, 1);
        this.totalDocs -= 1;
      });
    },
    checkDealer(type){
      if (type === "dealership") return "Dealer"
    }
  },
  computed: {
    totalPage: function() {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    }
  },
  watch: {
    "dataTableParams.page": function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateDealerVehiclePage(newVal);
        this.dataTableParams.page = newVal;
        this.getDealerCarList();
      }
    },
    "dataTableParams.limit": function(newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.updateDealerVehiclePageLimit(newlimit);
        // this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getDealerCarList();
      }
    },
    "dataTableParams.search": function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = 1;
        this.updateDealerVehiclePageSearch(newVal)
        this.dataTableParams.search = newVal;
        this.getDealerCarList();
      }
    },
  },
  created() {
    this.initToFirstPage({pageType: "dealerCar"});
    this.getDealerCarList();
  }
};
</script>

<style>
.img-container {
  /* border: 1px solid #ccc; */
  float: left;
  width: 150px;
  height:100px;
  display: flex;
  justify-content: center;
}

.image img {
  max-width: 150px;
  max-height: 100px;
  height: auto;
  object-fit: contain;
}
.image {
  display:flex;
  align-self: center;
}
</style>