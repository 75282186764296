 
<template>
  <vx-card slot="no-body" class="tabs-container px-6 pt-6">
    <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
      <vs-tab label="Basic" icon-pack="feather" icon="icon-flag">
        <dealer-basic-details class="mt-4" :data="dealerDetail"></dealer-basic-details>
      </vs-tab>
      <vs-tab label="List of Dealer Cars" icon-pack="feather" icon="icon-shopping-bag">
        <div class="tab-text">
          <car-list-dealer class="mt-4" :id="dealerId"/>
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>

<script>
import { mapActions } from "vuex";
import DealerBasicDetails from "./dealerBasicDetails.vue";
import CarListDealer from "./carListDealer.vue";

export default {
  name: "CarDetails",
  components: {
    DealerBasicDetails,
    CarListDealer,
  },
  data() {
    return {
      dealerId: "",
      dealerDetail: {},
      activeTab: 0,
      back() {
        this.$router.push({ name: "Vehicles" });
      },
    };
  },

  methods: {
    ...mapActions("carDealer", ["fetchCarDealerDetail"]),
    getDealerDetail(id) {
      let self = this;
      this.fetchCarDealerDetail(id).then((res) => {
        self.dealerDetail = res.data.data;
      });
    },
  },

  created() {
    this.dealerId = this.$route.params.id;
    this.getDealerDetail(this.dealerId)
  },
};
</script> 
<style >
.ql-editor {
  min-height: 200px;
}
</style> 